<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <!-- ADD DEPARTMENT FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card title="MODULES">
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full">
              <div class>
                <vs-select
                  v-validate="'required'"
                  v-model="form.company_id"
                  name="Role"
                  placeholder="Role"
                  class="select-large w-full"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.company_id"
                    :text="item.company_name"
                    v-for="(item, index) in companies"
                  />
                </vs-select>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Company Name')"
                  >{{ errors.first("Company Name") }}</span
                >
              </div>
            </div>
          </div>

          <div></div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";

const VxTour = () => import("@/components/VxTour.vue");
import { BASEURL } from "@/config/index";

export default {
  components: {},
  data() {
    return {
      dropzoneOptions: {
        url: BASEURL() + "/v2/uploads",
        thumbnailWidth: 150,
        maxFilesize: 1,
        maxFiles: 1,
        acceptedFiles: "image/*",
        headers: { "My-Awesome-Header": "header value" },
      },
      imageUpload: {
        image_url: "",
        deleteButtonStatus: false,
      },
      switch1: true,
      submitStatus: false,
      User: "Select User",
      form: {
        company_name: "",
        company_email: "",
        company_phone: "",
        country_id: 1,
        company_address: "",
        company_active: 1,
        company_url: "",
        company_logo: "-",
        company_description: "-",
      },
      companies: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {},
  methods: {
    clearForm: function () {
      Object.keys(this.form).map((item, index) => {
        this.form[item] = "";
      });
    },
  },
  mounted() {},
};
</script>
